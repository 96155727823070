import React from "react";
import Form_DentalPetV2 from "../../form/form_dentalWillowPark";

export default function Form_DentalPetV2VMCUC() {
  const clinic_id = "65a3ab30623c3dbb797a8f9a";

  return (
    <div className="w-full flex justify-center items-center">
      <Form_DentalPetV2 clinic_id={clinic_id} />
    </div>
  );
}
