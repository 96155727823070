import Form from "./form/form";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FormDentipet from "./form/form_dentipet";
import RecoverPassword from "./pages/recoverPassword";
import PageNotFound from "./pages/redirect";
import FormPotentialUserWillow from "./pages/formPotentialUsersWilllow";
import FormPotentialUserAcacia from "./pages/formPotentialUsersAcacia.jsx";
import FormPotentialUserCentralValley from "./pages/formPotentialUsersCentralValley.jsx";
import FormPotentialUserLancaster from "./pages/formPotentialUsersLancaster.jsx";
import FormPotentialUserPleasanton from "./pages/formPotentialUsersPleasanton.jsx";
import FormPotentialUserSimi from "./pages/formPotentialUsersSimiValley.jsx";
import FormPotentialUserVMCUC from "./pages/formPotentialUsersVMCUC.jsx";
import FormDentalVMCUC from "./pages/formDental/formDentalVMCUC.jsx";
import FormDentalAcacia from "./pages/formDental/formDentalAcacia.jsx";
import FormDentalWillow from "./pages/formDental/formDentalWillow.jsx";
import FormDentalPleansanton from "./pages/formDental/formDentalPleansanton.jsx";
import Form_DentalPetV2BLVD from "./pages/formDental/formDentalBLVD.jsx";
import Test from "./pages/test.jsx";
import Form_DentalPetPark from "./pages/formDental/formDentalWillowPark.jsx";
import FormDentalAMCPPark from "./pages/formDental/formDentalAMCPPark.jsx";
import Referal from "./pages/form_referal/referal.jsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Referal />} />
        <Route path="/main_form" element={<Form />} />
        <Route path="/dentipet" element={<FormDentipet />} />
        <Route path="/Recover_password" element={<RecoverPassword />} />
        {/* <Route path="/*" element={<PageNotFound />} /> */}
        <Route path="/form_Willow" element={<FormPotentialUserWillow />} />
        <Route path="/form_Acacia" element={<FormPotentialUserAcacia />} />
        <Route path="/form_AmericaVet" element={<FormPotentialUserSimi />} />
        <Route path="/form_dental_acacia" element={<FormDentalAcacia />} />
        <Route path="/form_dental_willow" element={<FormDentalWillow />} />
        <Route
          path="/form_dental_pleansanton"
          element={<FormDentalPleansanton />}
        />
        <Route path="/form_dental_vmcuc" element={<FormDentalVMCUC />} />
        <Route
          path="/form_Lancaster"
          element={<FormPotentialUserLancaster />}
        />
        <Route
          path="/form_dental_Lancaster"
          element={<Form_DentalPetV2BLVD />}
        />
        <Route
          path="/form_CentralValley"
          element={<FormPotentialUserCentralValley />}
        />
        <Route
          path="/form_Pleasanton"
          element={<FormPotentialUserPleasanton />}
        />
        <Route path="/form_vmcuc" element={<FormPotentialUserVMCUC />} />
        <Route path="/test" element={<Test />} />
        <Route
          path="/form_dental_Willow_Park"
          element={<Form_DentalPetPark />}
        />
        <Route path="/form_dental_AMCP_Park" element={<FormDentalAMCPPark />} />
      </Routes>
    </Router>
  );
}

export default App;
