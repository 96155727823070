import React, { useEffect, useState } from "react";
import classNames from "classnames";
import globalVariables from "../../globalVariables";
import axios from "axios";
import Select from "react-select";
import Spinner from "../../components/spinner";
import Check from "../../img/checking1.png";

function ModalForm({ isModalOpen, toggleModal }) {
  const [step, setStep] = useState(1); // Estado para controlar el paso actual
  const totalSteps = 15; // Total de pasos en el formulario
  const [formData, setFormData] = useState({
    zip_code: "",
    pet_name: "",
    request: "",
    pet_type: "",
    pet_breeds: "",
    weight: "",
    gender: "",
    neutered_or_payed: "",
    birthday: "",
    owner_name: "",
    phone_number: "",
    email: "",
  });

  useEffect(() => {
    setFormData({ ...formData, ["pet_breeds"]: "" });
  }, [formData.pet_type]);

  const [fieldError, setFieldError] = useState(false);

  const [dogsBreeds, setDogsBreeds] = useState([]);
  const [catsBreeds, setCatsBreeds] = useState([]);
  // const [petTypeSelect, setPetTypeSelect] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [image, setImage] = useState("");
  const [imageFront, setImageFront] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [imageQuote, setImageQuote] = useState("");
  const [imageFrontQuote, setImageFrontQuote] = useState("");
  const [analizeAi, setAnalizeAi] = useState(false);
  const [messageToClient, setMessageToClient] = useState("");

  // const handleImageUpload = (e, set, setFront) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();

  //   setFront(URL.createObjectURL(file));

  //   reader.onloadend = () => {
  //     set(reader.result);
  //   };

  //   if (file) {
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleImageUpload = (e, setImageFile, setPreview) => {
    const file = e.target.files[0];
    if (!file) return;

    // Guarda el archivo en un hook para su posterior envío
    setImageFile(file);

    // Opcional: crear una vista previa para mostrar la imagen en el frontend
    setPreview(URL.createObjectURL(file));
  };

  const getCatsBreeds = async () => {
    try {
      const response = await axios.get(`${globalVariables.domain}/cat_breed`);
      // console.log(response.data)
      if (response.data.success === true) {
        // console.log(response.data.content)
        setCatsBreeds(
          response.data.content.map((breed) => ({
            value: breed.name,
            label: breed.name,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDogsBreeds = async () => {
    try {
      const response = await axios.get(`${globalVariables.domain}/dog_breed`);
      // console.log(response.data)
      if (response.data.success === true) {
        // console.log(response.data.content)
        setDogsBreeds(
          response.data.content.map((breed) => ({
            value: breed.name,
            label: breed.name,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitForm = async () => {
    console.log(formData);

    // return;
    let imageUrl = "";
    let imageQuoteUrl = "";
    setSpinner(true);
    // return
    if (image !== "") {
      imageUrl = await postImageUrlV2(image);
    }

    if (imageQuote !== "") {
      imageQuoteUrl = await postImageUrlV2(imageQuote);
    }

    // return;
    try {
      const response = await axios.post(
        `${globalVariables.domain}/form_low_cost`,
        {
          zip_code: formData.zip_code,
          pet_name: formData.pet_name,
          request: formData.request,
          pet_type: formData.pet_type,
          pet_breeds: formData.pet_breeds,
          weight: formData.weight,
          gender: formData.gender,
          neutered_or_payed: formData.neutered_or_payed,
          owner_name: formData.owner_name,
          phone_number: formData.phone_number,
          email: formData.email,
          imageUrl: image === "" ? "" : imageUrl,
          imageQuoteUrl: imageQuoteUrl === "" ? "" : imageQuoteUrl,
          analizeAi: analizeAi,
          birthday: formData.birthday,
        }
      );
      // console.log(response.data)
      if (response.data.success === true) {
        setMessageToClient(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // async function postImageUrlV2(img) {
  //   const imageBlob = base64ToBlob(img);
  //   const formData = new FormData();
  //   formData.append("image", imageBlob);

  //   try {
  //     const responseImg = await axios.post(
  //       `${globalVariables.domain}/file`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data", // Asegúrate de establecer el tipo de contenido correcto
  //         },
  //       }
  //     );

  //     if (responseImg.data.success === true) {
  //       console.log(responseImg.data.content[0]);
  //       return responseImg.data.content[0];
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  async function postImageUrlV2(file) {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const responseImg = await axios.post(
        `${globalVariables.domain}/file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (responseImg.data.success === true) {
        console.log(responseImg.data.content[0]);
        return responseImg.data.content[0];
      }
    } catch (error) {
      console.error(error);
    }
  }

  function base64ToBlob(base64String) {
    const sinPrefijo = base64String.split(",")[1];
    // Convertir la cadena base64 a un array de bytes
    const byteCharacters = atob(sinPrefijo);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    // Crear un objeto Blob a partir del array de bytes
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Asignar el nombre de archivo al Blob
    blob.name = "foto.jpg";
    const archivo = new File([blob], blob.name, { type: "image/jpeg" });
    return archivo;
    // return blob;
  }

  useEffect(() => {
    getCatsBreeds();
    getDogsBreeds();
  }, []);

  // Mapeo de pasos a campos obligatorios
  const requiredFields = {
    1: ["zip_code"],
    2: ["pet_name"],
    3: ["request"],
    4: ["pet_type"],
    5: ["pet_breeds"],
    6: ["weight"],
    7: ["gender"],
    8: ["neutered_or_payed"],
    9: ["birthday"],
    10: ["owner_name"],
    11: ["phone_number"],
    12: ["email"],
  };

  // Manejar cambios en los campos del formulario
  const handleChange = (e) => {
    if (e.target.value !== "") {
      setFieldError(false);
    } else {
      setFieldError(true);
    }
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeBreeds = (e) => {
    if (e.length > 0) {
      setFieldError(false);
    } else {
      setFieldError(true);
    }

    setFormData({ ...formData, ["pet_breeds"]: e });
  };

  const validateStep = () => {
    const fieldsToValidate = requiredFields[step] || [];

    return fieldsToValidate.every((field) => {
      // console.log(`Validando el campo: ${field}, Valor:`, formData[field]);

      return field === "pet_breeds"
        ? Array.isArray(formData[field]) && formData[field].length > 0
        : typeof formData[field] === "string" && formData[field].trim() !== "";
    });
  };

  // Navegar entre pasos
  const nextStep = async () => {
    await setFieldError(false);
    if (!validateStep()) {
      setFieldError(true);
      return;
    }
    if (step === totalSteps - 1) {
      submitForm();
      // console.log("final");
      // return;
    }

    setFieldError(false);
    if (step < totalSteps) setStep(step + 1);
  };

  const prevStep = () => {
    setFieldError(false);
    if (step > 1) setStep(step - 1);
  };

  //animate-jump-in

  // useEffect(() => {
  //   if (spinner) {
  //     const timer = setTimeout(() => {
  //       setSpinner(false);
  //     }, 7000);

  //     // Limpiar el tiempo de espera si el componente se desmonta
  //     return () => clearTimeout(timer);
  //   }
  // }, [spinner]);

  useEffect(() => {
    if (messageToClient !== "") {
      setSpinner(false);
    }
  }, [messageToClient]);

  useEffect(() => {
    if (!isModalOpen && step === totalSteps) {
      window.location.reload();
    }
  }, [isModalOpen]);

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div
            className={classNames(
              "bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative animate-jump-in"
            )}
          >
            {/* Botón de cierre */}
            <button
              type="button"
              onClick={() => {
                setFieldError(false);
                toggleModal();
              }}
              className="absolute top-2 right-2 bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span className="sr-only">Close menu</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-xl font-bold mb-4">Service Request Form</h2>

            {/* Barra de progreso */}

            {step !== totalSteps && (
              <div className="relative w-full h-2 bg-gray-300 rounded mb-6">
                <div
                  className="absolute top-0 left-0 h-full bg-blue-500 rounded transition-all"
                  style={{ width: `${(step / totalSteps) * 100}%` }}
                ></div>
              </div>
            )}

            {/* Contenido del formulario por pasos */}
            <form
              className={classNames("", {
                "animate-shake": fieldError,
              })}
            >
              {step === 1 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    Zip code <a className="text-red-600 font-bold">*</a>
                  </label>
                  <input
                    type="number"
                    name="zip_code"
                    value={formData.zip_code}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder="90001"
                  />
                </div>
              )}

              {step === 2 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    Pet name <a className="text-red-600 font-bold">*</a>
                  </label>
                  <input
                    label="Pets Name"
                    type="text"
                    name="pet_name"
                    value={formData.pet_name}
                    placeholder="Pet Name"
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-3 py-2"
                  />
                </div>
              )}

              {step === 3 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    Could you please tell us briefly what treatment{" "}
                    {formData.pet_name}'s needs?
                    <a className="text-red-600 font-bold">*</a>
                  </label>
                  <textarea
                    name="request"
                    value={formData.request}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    rows="4"
                    placeholder="Describe the service you need"
                  ></textarea>

                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    Would you like AI to help analyze {formData.pet_name}’s
                    health concern?
                  </label>
                  <div className="flex items-center ">
                    <input
                      type="checkbox"
                      name="aiAnalysis"
                      checked={analizeAi}
                      onChange={() => setAnalizeAi(!analizeAi)}
                      className="w-4 h-4 text-red-600 border-gray-300 rounded focus:ring-red-500 animate-jump-in"
                    />
                    <span className="text-sm text-gray-700">
                      Yes, I would like AI assistance
                    </span>
                  </div>
                </div>
              )}

              {step === 4 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    What type of pet is {formData.pet_name}?
                    <a className="text-red-600 font-bold">*</a>
                  </label>
                  <select
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    defaultValue={formData.pet_type}
                    onChange={handleChange}
                    name="pet_type"
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="dog">Dog</option>
                    <option value="cat">Cat</option>
                  </select>
                </div>
              )}

              {step === 5 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    What breed is {formData.pet_name}? (Max 2){" "}
                    <a className="text-red-600 font-bold">*</a>
                  </label>
                  <Select
                    name="pet_breeds"
                    defaultValue={
                      formData.pet_breeds === ""
                        ? ""
                        : formData.pet_breeds.map((breed) => ({
                            value: breed.value,
                            label: breed.label,
                          }))
                    }
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    options={
                      disabled === true
                        ? []
                        : formData.pet_type === "dog"
                        ? dogsBreeds
                        : catsBreeds
                    }
                    isMulti
                    isDisabled={formData.pet_type === "" ? true : false}
                    onChange={(e) => {
                      handleChangeBreeds(e);
                      if (e.length > 1) {
                        setDisabled(true);
                      } else {
                        setDisabled(false);
                      }
                    }}
                  />
                </div>
              )}

              {step === 6 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    "How much does {formData.pet_name} weigh? (in lbs)"{" "}
                    <a className="text-red-600 font-bold">*</a>
                  </label>
                  <input
                    type="number"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder=""
                  />
                </div>
              )}

              {step === 7 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    What is {formData.pet_name}’s gender?
                    <a className="text-red-600 font-bold">*</a>
                  </label>
                  <select
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    defaultValue={formData.gender}
                    onChange={handleChange}
                    name="gender"
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              )}

              {step === 8 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    Has {formData.pet_name} been sterilized (neutered or
                    spayed)?
                    <a className="text-red-600 font-bold">*</a>
                  </label>
                  <select
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    defaultValue={formData.neutered_or_payed}
                    onChange={handleChange}
                    name="neutered_or_payed"
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                    <option value="unsure">unsure</option>
                  </select>
                </div>
              )}

              {step === 9 && (
                <div className="mb-4">
                  <label
                    htmlFor="birthday"
                    className="block text-sm font-semibold mb-2"
                  >
                    {formData.pet_name}'s Birthday{" "}
                    <a className="text-red-600 font-bold">*</a>
                  </label>
                  <input
                    type="date"
                    id="birthday"
                    name="birthday"
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    onChange={handleChange}
                    value={formData.birthday}
                  />
                </div>
              )}

              {step === 10 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    What is your name? (owner name)
                    <a className="text-red-600 font-bold">*</a>
                  </label>
                  <input
                    type="text"
                    name="owner_name"
                    value={formData.owner_name}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder=""
                  />
                </div>
              )}

              {step === 11 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    Phone Number <a className="text-red-600 font-bold">*</a>
                  </label>
                  <input
                    type="number"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder=""
                  />
                </div>
              )}

              {step === 12 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    Email <a className="text-red-600 font-bold">*</a>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded px-3 py-2"
                    placeholder=""
                  />
                </div>
              )}

              {step === 13 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    Please include a photo of the affected area of{" "}
                    {formData.pet_name} if it could help with the assessment:
                  </label>
                  <div className="flex items-center justify-center mt-4 mb-4">
                    <label
                      htmlFor="imageUpload"
                      className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 transition duration-300 text-sm"
                    >
                      Upload Image
                    </label>
                    <input
                      type="file"
                      id="imageUpload"
                      name="imageUpload"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) =>
                        handleImageUpload(e, setImage, setImageFront)
                      }
                    />
                  </div>
                  {imageFront !== "" && (
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        marginTop: 10,
                      }}
                    >
                      <img
                        src={imageFront}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  )}
                </div>
              )}

              {step === 14 && (
                <div className="mb-4">
                  <label className="block text-sm font-semibold mb-2 animate-fade-left">
                    If you have a photo of a quote from another clinic, feel
                    free to upload it here.
                  </label>
                  <div className="flex items-center justify-center mt-4 mb-4">
                    <label
                      htmlFor="imageUpload"
                      className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 transition duration-300 text-sm"
                    >
                      Upload Image
                    </label>
                    <input
                      type="file"
                      id="imageUpload"
                      name="imageUpload"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) =>
                        handleImageUpload(e, setImageQuote, setImageFrontQuote)
                      }
                    />
                  </div>
                  {imageFrontQuote !== "" && (
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        marginTop: 10,
                      }}
                    >
                      <img
                        src={imageFrontQuote}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  )}
                </div>
              )}

              {step === 15 && (
                <div className="">
                  <div className="flex items-center justify-center">
                    {spinner ? (
                      <Spinner />
                    ) : (
                      <>
                        <div className="mb-4">
                          <div className="justify-center flex items-center mb-4">
                            <img
                              src={Check}
                              className="w-12 h-12 rounded-full bg-white w-16 h-16 animate-jump-in"
                            />
                          </div>

                          <div>
                            {messageToClient !== "" && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: messageToClient,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              {fieldError && (
                <a className="text-red-500 animate-jump-in ">
                  This field is required *
                </a>
              )}

              {/* Navegación entre pasos */}

              {step !== totalSteps && (
                <div className="flex justify-between mt-4">
                  <button
                    type="button"
                    onClick={prevStep}
                    className={`px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 w-32 ${
                      step === 1 && "opacity-50 pointer-events-none"
                    }`}
                  >
                    Back
                  </button>
                  {step < totalSteps - 1 ? (
                    <button
                      type="button"
                      onClick={nextStep}
                      className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 w-32"
                    >
                      {step === 13 ? (image === "" ? "Skip" : "Next") : "Next"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 w-32"
                      onClick={nextStep}
                    >
                      Submit
                    </button>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default ModalForm;
