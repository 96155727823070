import React, { useState } from "react";
import ModalForm from "./modalForm";

function Referal() {
  // Estado para controlar la visibilidad del modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Función para abrir/cerrar el modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="bg-[#ffc400]">
      <ModalForm toggleModal={toggleModal} isModalOpen={isModalOpen} />
      {/* Encabezado */}
      <div className="flex justify-between items-center p-4 bg-[#2f3944] text-white">
        <div className="text-xl font-bold">Low cost Vet Referral</div>
        <nav className="flex space-x-4">
          <a href="#services" className="hover:text-blue-400">
            Services
          </a>
          <a href="#about" className="hover:text-blue-400">
            About Us
          </a>
          <a href="#contact" className="hover:text-blue-400">
            Contact
          </a>
        </nav>
      </div>

      {/* Sección principal */}
      <section
        className="text-center py-16 bg-cover bg-center text-white"
        // style={{ backgroundImage: "url('clinic-hero.jpg')" }}
      >
        <h1 className="text-4xl font-bold pt-20">
          Welcome to Low cost Vet Referral
        </h1>
        <p className="mt-4 text-lg">
          Dedicated to providing exceptional care for your furry family members.
        </p>
        <button
          className="mt-6 px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg"
          onClick={toggleModal}
        >
          Submit a Service Request Form
        </button>
      </section>

      {/* Servicios */}
      <section id="services" className="py-16 bg-gray-100">
        <h2 className="text-3xl font-bold text-center mb-8">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-4 max-w-5xl mx-auto">
          <div className="p-6 bg-white shadow-lg rounded-lg text-center">
            <h3 className="text-xl font-semibold mb-2">Wellness Exams</h3>
            <p>Comprehensive health checks to ensure your pet’s well-being.</p>
          </div>
          <div className="p-6 bg-white shadow-lg rounded-lg text-center">
            <h3 className="text-xl font-semibold mb-2">Vaccinations</h3>
            <p>Keep your pet protected with our tailored vaccination plans.</p>
          </div>
          <div className="p-6 bg-white shadow-lg rounded-lg text-center">
            <h3 className="text-xl font-semibold mb-2">Surgery</h3>
            <p>Expert surgical care for routine and advanced procedures.</p>
          </div>
        </div>
      </section>

      {/* Sobre nosotros */}
      <section id="about" className="py-16 bg-white text-center">
        <h2 className="text-3xl font-bold mb-4">About Us</h2>
        <p className="max-w-3xl mx-auto">
          At Low cost Vet Referral, our mission is to provide compassionate,
          high-quality care for pets and their families. Our experienced team is
          committed to promoting the health and happiness of your beloved
          companions.
        </p>
      </section>

      {/* Contacto */}
      <section id="contact" className="py-16 bg-gray-100 text-center">
        <h2 className="text-3xl font-bold mb-4">Contact Us</h2>
        <p className="mb-6">
          Need to schedule an appointment or have questions? Get in touch with
          us!
        </p>
        <button className="px-6 py-3 bg-green-500 hover:bg-green-600 text-white rounded-lg">
          Get in Touch
        </button>
      </section>

      {/* Pie de página */}
      <footer className="bg-gray-800 text-white text-center py-4">
        <p>
          &copy; {new Date().getFullYear()} Low cost Vet Referral. All rights
          reserved.
        </p>
      </footer>
    </div>
  );
}

export default Referal;
